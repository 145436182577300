<script setup lang="ts">
import { computed } from 'vue'
import { formatPoints } from '/~/utils/points'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

type Status = 'preview' | 'pending' | 'completed'

interface Props {
  points: number
  status: Status
  level: string
  borderX?: boolean
  borderY?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  borderX: true,
  borderY: true,
})

const statusMetadata = computed(() => {
  const data: Record<
    Status,
    { text: string; borderColor: string; textColor: string; bgColor?: string }
  > = {
    preview: {
      text: 'You will earn',
      borderColor: 'border-info',
      textColor: 'text-fg-info',
      bgColor: 'bg-info',
    },
    pending: {
      text: 'Pending',
      borderColor: 'border-warning',
      textColor: 'text-fg-warning',
      bgColor: 'bg-warning',
    },
    completed: {
      text: 'Completed',
      borderColor: 'border-success',
      textColor: 'text-fg-success',
      bgColor: 'bg-success',
    },
  }

  return (
    data[props.status] || {
      text: '',
      borderColor: 'border-black',
      textColor: 'text-black',
    }
  )
})
</script>

<template>
  <div
    :class="{
      [`${statusMetadata.borderColor} ${statusMetadata.textColor} mt-10`]: true,
      'border-x': borderX,
      'border-y': borderY,
    }"
  >
    <div
      :class="`${statusMetadata.bgColor} flex flex-col items-center justify-center py-3 xs:flex-row`"
    >
      <div class="flex items-center">
        <base-icon
          :class="statusMetadata.textColor"
          svg="points/token"
          :size="24"
          class="mr-1"
        />
        <span class="ml-1 text-xl font-semibold tracking-[0.16rem]">
          {{ level }}
        </span>
      </div>
      <template v-if="points">
        <div
          class="mx-6 hidden h-6 w-0 border-l xs:block"
          :class="statusMetadata.borderColor"
        />
        <div class="block text-center text-xl">
          {{ statusMetadata.text }}
          <span class="font-bold">{{ formatPoints(points) }} Points</span>
        </div>
      </template>
    </div>
  </div>
</template>
